
import { IPost } from '@/models/post';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    post: {
      type: Object as () => IPost,
      required: true,
    },
  },
  setup: props => {
    const content = computed(() => {
      const $div = document.createElement('div');
      $div.innerHTML = props.post.post_content || '';
      return $div.textContent?.substring(0, 100) || '';
    });

    const replace = (e: Event) => {
      const $img = e.target as HTMLImageElement;
      $img.src = require('@/assets/img/night-sorbet.png');
    };

    return {
      replace,
      content,
    };
  },
});
