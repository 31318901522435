
import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  props: {
    page: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    range: {
      type: Number,
      default: 2,
    },
    basePath: {
      type: String,
      default: '/page',
    },
    search: {
      type: String,
      default: '',
    },
  },
  setup: props => {
    const $router = useRouter();
    const pages = computed(() => {
      const total = Array(props.total)
        .fill(0)
        .map((v, i) => i + 1);

      let page = props.page;
      if (page > props.total) {
        page = props.total;
      }

      const center = [page];
      for (let i = 1; i < props.range; i++) {
        const prev = page - i;
        const next = page + i;
        if (prev > 0) {
          center.unshift(prev);
        }
        if (next < props.total) {
          center.push(next);
        }
      }

      const left = total.slice(0, props.range);
      const right = total.slice(-props.range);
      const result = Array.from(new Set([...left, ...center, ...right]));

      const dotted: number[] = [];
      result.forEach((v, i, self) => {
        if (i > 0 && v - 1 !== self[i - 1]) {
          dotted.push(i);
        }
        return v;
      });

      dotted.forEach((v, i) => {
        result.splice(v + i, 0, 0);
      });

      return result;
    });

    const pageClicked = (page: number) => {
      if (props.search === '') {
        $router.push({ path: `${props.basePath}/${page}` });
        return;
      }

      $router.push({ path: `${props.basePath}/${page}`, query: { search: props.search } });
      return;
    };

    return {
      pages,
      pageClicked,
    };
  },
});
