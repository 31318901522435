<script lang="ts">
import { IPost } from '@/models/post';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    post: {
      type: Object as () => IPost,
      required: true,
    },
  },
  setup: props => {
    const content = computed(() => {
      const $div = document.createElement('div');
      $div.innerHTML = props.post.post_content || '';
      return $div.textContent?.substring(0, 100) || '';
    });

    const replace = (e: Event) => {
      const $img = e.target as HTMLImageElement;
      $img.src = require('@/assets/img/night-sorbet.png');
    };

    return {
      replace,
      content,
    };
  },
});
</script>

<template>
  <div :class="$style.component">
    <div :class="$style.wrapper">
      <img v-if="post.thumbnail" :class="$style.img" :src="post.thumbnail" :alt="`${post.ID}画像`" @error="replace" />
      <img v-else :class="$style.img" src="@/assets/img/night-sorbet.png" :alt="`${post.ID}画像`" />
    </div>
    <div :class="$style.context">
      <p :class="$style.title">{{ post.post_title }}</p>
      <div>
        <p :class="$style.content">{{ content }}</p>
      </div>
      <p :class="$style.categories">
        <span v-for="(category, i) in post.categories" :key="i" :class="$style.category">{{ category.name }}</span>
      </p>
    </div>
  </div>
</template>

<style lang="scss" module>
.component {
  width: 100%;
  cursor: pointer;
  background-color: $color-light;
  display: flex;
  flex-direction: column;
  height: 360px;

  .wrapper {
    width: 100%;
    height: 160px;

    .img {
      height: 100%;
      width: 100%;
      display: block;
      object-fit: cover;
    }
  }

  .context {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
    padding: 8px;
    box-sizing: border-box;

    .title {
      display: -webkit-box;
      margin: 0;
      font-size: 14px;
      font-weight: bold;
      color: #3a76d7;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .content {
      display: -webkit-box;
      margin: 8px 0;
      font-size: 12px;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: $color-text-dark;
    }

    .categories {
      flex: 1;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;

      .category {
        font-size: 10px;
        color: #aaa;

        &:not(:first-child) {
          margin-left: 8px;
        }
      }
    }
  }

  &:hover {
    .context {
      .title {
        text-decoration-line: underline;
      }
    }
  }
}
</style>
