<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  props: {
    page: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    range: {
      type: Number,
      default: 2,
    },
    basePath: {
      type: String,
      default: '/page',
    },
    search: {
      type: String,
      default: '',
    },
  },
  setup: props => {
    const $router = useRouter();
    const pages = computed(() => {
      const total = Array(props.total)
        .fill(0)
        .map((v, i) => i + 1);

      let page = props.page;
      if (page > props.total) {
        page = props.total;
      }

      const center = [page];
      for (let i = 1; i < props.range; i++) {
        const prev = page - i;
        const next = page + i;
        if (prev > 0) {
          center.unshift(prev);
        }
        if (next < props.total) {
          center.push(next);
        }
      }

      const left = total.slice(0, props.range);
      const right = total.slice(-props.range);
      const result = Array.from(new Set([...left, ...center, ...right]));

      const dotted: number[] = [];
      result.forEach((v, i, self) => {
        if (i > 0 && v - 1 !== self[i - 1]) {
          dotted.push(i);
        }
        return v;
      });

      dotted.forEach((v, i) => {
        result.splice(v + i, 0, 0);
      });

      return result;
    });

    const pageClicked = (page: number) => {
      if (props.search === '') {
        $router.push({ path: `${props.basePath}/${page}` });
        return;
      }

      $router.push({ path: `${props.basePath}/${page}`, query: { search: props.search } });
      return;
    };

    return {
      pages,
      pageClicked,
    };
  },
});
</script>

<template>
  <ul :class="$style.component">
    <li v-for="(p, i) in pages" :key="i">
      <span v-if="p === 0" :class="[$style.page, $style.dots]">...</span>
      <span v-else-if="p === page" :class="[$style.page, $style.is_active]">{{ p }}</span>
      <!-- <router-link v-else :class="$style.page" :to="{ path: `${basePath}/${p}`, query: { search } }">{{
        p
      }}</router-link> -->
      <span v-else :class="$style.page" @click="pageClicked(p)">{{ p }}</span>
    </li>
  </ul>
</template>

<style lang="scss" module>
.component {
  display: flex;
  padding: 0;
  list-style: none;
  justify-content: center;

  li {
    &:not(:first-child) {
      margin-left: 8px;
    }
  }

  .page {
    display: inline-block;
    cursor: pointer;
    line-height: 48px;
    height: 48px;
    width: 48px;
    text-align: center;
    background-color: #aaa;
    color: $color-text-light;
  }

  .is_active,
  .dots {
    cursor: default;
    background-color: #ccc;
  }
}

@include mq(md) {
  .component {
    .page {
      line-height: 32px;
      height: 32px;
      width: 32px;
    }
  }
}
</style>
